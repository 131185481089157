import React, {
  useState,
  Suspense,
  useEffect,
  lazy,
  useRef,
  startTransition,
} from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { publicRoutes, privateRoutes, courseRoutes } from "./routes";
import { useSelector, useDispatch } from "react-redux";
import * as CS from "./styles/CommonStyled";
import * as LE from "./styles/LoginStyled";
import Navbar from "./components/Navbar";
import { RMlogin } from "./utils/api";
import bannerSample from "./assets/images/bannerSample.png";
import axios from "./utils/axios";
import Sidebar from "./components/Sidebar/Sidebar";
import backVideo from "./assets/images/backVideo.mp4";
// const Course = lazy(() => import("./pages/private/Courses/Course"));
import Courses from "./pages/private/Courses/CourseContent/Courses";
import LayoutPage from "./Layout";
import VideoComponent from "./VideoComponent";
import { setSelectLangJson } from "./store/action";

const PublicRoute = () => {
  const videoRef = useRef(null);
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const [bannerData, setBannerData] = useState([]);
  const getBanner = async () => {
    let url = RMlogin.getBanner;
    try {
      const { data } = await axios.get(url);
      setBannerData(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);
  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };
  return (
    <Routes>
      {publicRoutes.map((val, ind) => {
        return (
          <Route
            exact
            key={ind}
            path={val.path}
            element={
              <Suspense>
                <CS.MainContainer>
                  <CS.InnerContainerTwo>
                    <CS.BackContainer>
                      <video
                        ref={videoRef}
                        className="video-background"
                        width="320"
                        height="240"
                        autoPlay
                        loop
                        muted
                      >
                        <source src={backVideo} type="video/mp4" />
                      </video>
                      <val.component
                        navigate={navigate}
                        location={location}
                        dispatch={dispatch}
                        handlePauseVideo={pauseVideo}
                      />
                      {/* {window.location.pathname === "/" ? ( */}
                        <LE.BannerImageBox>
                          <LE.BannerTitle>
                            {bannerData?.showTextStatus
                              ? `“${bannerData?.showText}”`
                              : ""}
                          </LE.BannerTitle>
                          {bannerData?.showBannerImageStatus ? (
                            <LE.BannerImage
                              href={bannerData?.bannerLink}
                              target="_blank"
                            >
                              <img src={bannerData?.bannerImage} alt="" />
                            </LE.BannerImage>
                          ) : (
                            ""
                          )}
                        </LE.BannerImageBox>
                      {/* ) : (
                        ""
                      )} */}
                    </CS.BackContainer>
                  </CS.InnerContainerTwo>
                </CS.MainContainer>
              </Suspense>
            }
          />
        );
      })}
      <Route path="/*" element={<Navigate to="/learner" />} />
    </Routes>
  );
};
const PrivateRoute = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const courseId = useSelector((state) => state?.courseId);
  const selectLang = useSelector((state) => state?.selectLang);
  const languages = useSelector((state) => state?.languages);
  const [localState, setLocalState] = useState({});
  useEffect(() => {
    if (selectLang) {
      let filterLangJson = languages?.find((val) => val._id === selectLang);
      dispatch(
        setSelectLangJson(JSON.parse(filterLangJson?.localization_json))
      );
      const modifiedLangJson = {
        ...JSON.parse(filterLangJson?.localization_json),
        // profile_details: "Profile Details",
        // update_your_photo_and_personal_details_here:
        //   "Update your photo and personal details here",
        // user_name: "User Name",
        // email_id: "Email ID",
        // upload_profile: "Upload Profile",
        // cancel: "Cancel",
        // save_changes: "Save Changes",
        // great: "Great!",
        // your_data_has_been_successfully_saved:
        //   "Your data has been successfully saved.",
        // continue: "Continue",
        // password: "Password",
        // please_enter_your_current_password_to_change_your_password:
        //   "Please enter your current password to change your password",
        // current_password: "Current password",
        // new_password: "New Password",
        // your_new_password_must_be_more_than_characters:
        //   "Your new password must be more than 8 characters",
        // confirm_new_password: "Confirm new password",
        // update_password: "Update Password",
        // account_settings: "Account Settings",
        // profile_details: "Profile Details",
        // password: "Password",
        // warning: "Warning!",
        // if_you_change_the_video_language:
        //   "If you change the video language, the video will continue from where you left off.",
        // cancel: "Cancel",
        // ok_continue: "Ok, Continue",
        // progress: "progress",
        // leave_a_review: "Leave a review",
        // please_fill_the_feedback_form:
        //   "Please fill the feedback form . Your feedback is valuable for us.",
        // episode_complete: "Episode Completed",
      };
      dispatch(setSelectLangJson(modifiedLangJson));
      console.log(JSON.parse(filterLangJson?.localization_json), "Lang Json");
    }
  }, [selectLang]);

  return (
    <Routes>
      <Route path="/" element={<LayoutPage />}>
        {privateRoutes.map((val, ind) => (
          <Route
            key={ind}
            path={val.path}
            element={
              <Suspense>
                <val.component
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  courseId={courseId}
                  localState={localState}
                />
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route path="/" element={<Courses />}>
        {courseRoutes.map((val, ind) => (
          <Route
            key={ind}
            path={val.path}
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <val.component
                  navigate={navigate}
                  dispatch={dispatch}
                  location={location}
                  courseId={courseId}
                  theme={theme}
                  localState={localState}
                />
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route path="/*" element={<Navigate to="/learner/dashboard" />} />
    </Routes>
  );
};

const App = () => {
  // const state = useSelector((state) => state?.userData);
  const videoRef = useRef(null);
  const access_token = useSelector((state) => state?.access_token);
  const [backVideo, setBackVideo] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  let firstPlay =
    "https://s3rain.s3.ap-south-1.amazonaws.com/1721220010340_WorkFlix+Animation+Logo+.mp4";

  useEffect(() => {
    if (currentTime > 2) {
      if (currentTime === duration) {
        setBackVideo(false);
      }
    }
  }, [currentTime, duration]);

  console.log(duration, "duration");
  return (
    <CS.ParentBox>
      {access_token ? (
        backVideo ? (
          <VideoComponent
            handleDuration={setDuration}
            handleCurrentTime={setCurrentTime}
            firstPlay={firstPlay}
          />
        ) : (
          <PrivateRoute />
        )
      ) : (
        <PublicRoute />
      )}
    </CS.ParentBox>
    // <CS.ParentBox><PrivateRoute /></CS.ParentBox>
  );
};

export default App;
