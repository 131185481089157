import React, { useEffect, useState, startTransition } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar";
import * as CS from "./styles/CommonStyled";
import * as SEC from "./styles/SectionStyled";
import englishLang from "./utils/Languages/english.json";

const LayoutPage = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const courseId = useSelector((state) => state?.courseId);
  const [localState, setLocalState] = useState(englishLang?.learner);
  const access_token = useSelector((state) => state?.access_token);
  useEffect(() => {
    startTransition(() => {
      setLocalState(englishLang?.learner);
    });
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/learner/dashboard");
    }
  }, []);

  return (
    <CS.MainContainer themeColor={theme} isHeight={true}>
      <CS.InnerContainer themeColor={theme}>
        <CS.FirstContainer>
          <Sidebar
            theme={theme}
            navigate={navigate}
            dispatch={dispatch}
            localState={localState}
          />
        </CS.FirstContainer>
        <CS.SecondContainer themeColor={theme}>
          <Navbar
            navigate={navigate}
            dispatch={dispatch}
            theme={theme}
            localState={localState}
            accessToken={access_token}
          />
          <CS.ComContainer themeColor={theme}>
            <Outlet />
          </CS.ComContainer>
        </CS.SecondContainer>
      </CS.InnerContainer>
    </CS.MainContainer>
  );
};

export default LayoutPage;
