import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import "./video.css";

const VideoMedia = (props) => {
  const { videoURL1, videoURL2 } = props;
  const playerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentURL, setCurrentURL] = useState(videoURL1);
  const [progress, setProgress] = useState(0);
  const [videoDur, setVideoDur] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const { handleCurrentTime, timing, timeArr, blurTime, videoURL } = props;

  useEffect(() => {
    // Update current time every second
    const interval = setInterval(() => {
      if (playerRef.current && playerRef.current.getCurrentTime) {
        const currentTime = playerRef.current.getCurrentTime();
        // setCurrentTime(currentTime);
      }
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  const handleVideoEnd = () => {
    if (currentURL === videoURL1) {
      setCurrentURL(videoURL2); // Switch to the second video
      setIsPaused(false); // Autoplay the next video
    } else {
      // Optionally reset to the first video or handle end of playlist
      console.log("End of playlist");
    }
  };

  useEffect(() => {
    console.log("First move");
    if (timeArr?.includes(Math.floor(currentTime?.toFixed()))) {
      // setIsPaused(true); // Pause the video when the marker time is reached
      console.log("second move");
      // handleCurrentTime(currentTime + 1);
    }
  }, [currentTime]);

  return (
    <div
      style={{
        backgroundColor: "black",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ReactPlayer
        url={currentURL}
        controls={currentURL === videoURL1 ? false : true}
        width={800}
        height={450}
        style={{ borderRadius: "12px" }}
        playing={!isPaused}
        onEnded={handleVideoEnd}
        ref={playerRef}
      />
      {/* <button onClick={handleGetCurrentTime}>Get Current Time</button> */}
      <div
        style={{
          width: "95.3%",
          height: "10px",
          backgroundColor: "transparant",
          position: "relative",
        }}
      >
        {/* {timeArr?.map((time, index) => (
          <div
            key={index}
            className="custom-marker"
            style={{
              left: `${(time / videoDur) * 100}%`,
            }}
            onClick={() => handleMarkerClick(time)}
          />
        ))} */}
      </div>
    </div>
  );
};

export default VideoMedia;
