import axios from "axios";
import { API_URL_STAGING } from "../pages/constants/Statics";

const instance = axios.create({
  baseURL: API_URL_STAGING,
});

instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance.defaults.headers.common["Language"] =
  window.localStorage.getItem("rainLearnerLang") || "en";

if (localStorage.getItem("rainLearnerToken")) {
  const JWT_token = localStorage.getItem("rainLearnerToken");
  instance.defaults.headers.common["Authorization"] = `Bearer ${JWT_token}`;
}

export const updateAccessToken = (accessToken) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};
export const updateLang = (lang) => {
  instance.defaults.headers.common["Language"] = lang;
};

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("rainLearnerData");
      localStorage.removeItem("rainLearnerToken");
      localStorage.removeItem("rainLearnerLang");
      localStorage.removeItem("languagesObj");
      localStorage.removeItem("isActionDisabled");
      // localStorage.clear();
      window.location.href = "/";
    }

    // Return any error not to be handled
    return Promise.reject(error);
  }
);

export default instance;
